<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <input
          type="hidden"
          name="number_success_entries"
          v-validate="{ required: shouldValidateEntries }"
        />
        <div
          class="col-12 alert alert-danger"
          v-if="getError('number_success_entries')"
        >
          {{ errors.first("number_success_entries") }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <table class="table" :class="{ 'table-hover': withFiles.length }">
          <thead>
            <tr>
              <th>{{ $t("Name") }}</th>
              <th>{{ $t("TYPE") }}</th>
              <th>{{ $t("Status") }}</th>
              <th>{{ $t("Action") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- CAMPAIGN files (already saved) -->
            <tr v-for="(file, f) in campaignFiles" :key="f">
              <td>
                <div class="filename">{{ file.name }}</div>
              </td>

              <td>{{ file.file_type }}</td>
              <td>{{ getFileStatus(file) }}</td>
              <td>
                <FileDownload
                  v-if="file.processed"
                  :id="file.id"
                  btype="light"
                  tooltip="Original file"
                />
                <FileDownload
                  v-if="
                    file.file_type == 'TARGET_BASE' || file.file_type == 'ACCOUNT_OTHER' &&
                      file.processed &&
                      file._count_success > 0
                  "
                  :id="file.id"
                  :url="`/download/${file.id}/valids`"
                  :filename="`file_${file.id}_valids.zip`"
                  tooltip="Valid lines file"
                />
                <FileDownload
                  v-if="
                    file.file_type == 'TARGET_BASE' || file.file_type == 'ACCOUNT_OTHER' &&
                      file.processed &&
                      file._count_error > 0
                  "
                  btype="danger"
                  :id="file.id"
                  :url="`/download/${file.id}/errors`"
                  :filename="`file_${file.id}_errors.zip`"
                />
                <FileRemove
                  v-if="!disableRemove"
                  :id="file.id"
                  :deleteFile="file.file_type !== 'ACCOUNT_OTHER'"
                  @deleted="withFileRemoved(f, file)"
                />
              </td>
              <td>
                <div v-if="file.file_type == 'TARGET_BASE' || file.file_type == 'ACCOUNT_OTHER'">
                  <div class="legend">
                    <i class="fa fa-circle text-success"></i>
                    {{ $t("Valids") }} {{ getFileValidCount(file) }}
                  </div>
                  <div class="legend">
                    <i class="fa fa-circle text-danger"></i>
                    {{ $t("Invalids") }} {{ getFileInvalidCount(file) }}
                  </div>
                </div>
              </td>
            </tr>

            <!-- Dropped/Uploaded TARGET-BASEs -->
            <tr
              v-for="file in uploads.targetBaseFiles"
              :key="'tb-upload-' + file.id"
            >
              <td>
                <div class="filename">{{ file.name }}</div>
                <div class="progress" v-if="file.active">
                  <div
                    :class="getProgressClass(file)"
                    role="progressbar"
                    :style="{ width: file.progress + '%' }"
                  >
                    {{ Math.round(file.progress) }}%
                  </div>
                </div>
              </td>

              <td>{{ file.file_type }}</td>
              <td>{{ getUploadStatus(file) }}</td>
              <td>
                <!-- DOWNLOAD ORIGINAL FILE button -->
                <FileDownload
                  v-if="file.accountFile && file.accountFile.processed"
                  :id="file.accountFile.id"
                  btype="light"
                />

                <!-- DOWNLOAD CLEAN/VALIDS FILE button -->
                <FileDownload
                  v-if="
                    file.accountFile &&
                      file.accountFile.processed &&
                      file.accountFile._count_success > 0
                  "
                  :id="file.accountFile.id"
                  :url="`/download/${file.accountFile.id}/valids`"
                  :filename="`file_${file.accountFile.id}_valids.zip`"
                  tooltip="Valid lines file"
                />

                <!-- DOWNLOAD INVALIDS FILE button -->
                <FileDownload
                  v-if="
                    file.accountFile &&
                      file.accountFile.processed &&
                      file.accountFile._count_error > 0
                  "
                  btype="danger"
                  :id="file.accountFile.id"
                  :url="`/download/${file.accountFile.id}/errors`"
                  :filename="`file_${file.accountFile.id}_errors.zip`"
                />

                <!-- TRASH button -->
                <FileRemove
                  v-if="file.accountFile"
                  :id="file.accountFile.id"
                  :deleteFile="file.file_type !== 'ACCOUNT_OTHER'"
                  @deleted="removeUpload(file)"
                />
                <p-button
                  v-else
                  type="danger"
                  size="sm"
                  icon
                  @click="removeUpload(file)"
                >
                  <i class="fa fa-remove"></i>
                </p-button>
              </td>
              <td>
                <div v-if="file.accountFile">
                  <div class="legend">
                    <i class="fa fa-circle text-success"></i>
                    {{ $t("Valids") }} {{ getFileValidCount(file.accountFile) }}
                  </div>
                  <div class="legend">
                    <i class="fa fa-circle text-danger"></i>
                    {{ $t("Invalids") }}
                    {{ getFileInvalidCount(file.accountFile) }}
                  </div>
                </div>
              </td>
            </tr>

            <!-- Dropped/Uploaded BLACKLISTs -->
            <tr
              v-for="file in uploads.blacklistFiles"
              :key="'bl-upload-' + file.id"
            >
              <td>
                <div class="filename">{{ file.name }}</div>
                <div class="progress" v-if="file.active">
                  <div
                    :class="getProgressClass(file)"
                    role="progressbar"
                    :style="{ width: file.progress + '%' }"
                  >
                    {{ Math.round(file.progress) }}%
                  </div>
                </div>
              </td>
              <td>{{ file.file_type }}</td>
              <td>{{ getUploadStatus(file) }}</td>
              <td>
                <!-- DOWNLOAD ORIGINAL FILE button -->
                <FileDownload
                  v-if="file.accountFile"
                  :id="file.accountFile.id"
                />

                <!-- Action: TRASH file -->
                <FileRemove
                  v-if="file.accountFile"
                  :id="file.accountFile.id"
                  :deleteFile="file.file_type !== 'ACCOUNT_OTHER'"
                  @deleted="removeUpload(file)"
                />
                <p-button
                  v-else
                  type="danger"
                  size="sm"
                  icon
                  @click="removeUpload(file)"
                >
                  <i class="fa fa-remove"></i>
                </p-button>
              </td>
              <td>
                <!-- las BLACKLISTs NO se validan -->
              </td>
            </tr>
          </tbody>
          <tfoot v-if="totalFiles > 0">
            <tr>
              <td colspan="5">
                <span class="legend text-uppercase">
                  <i class="fa fa-circle text-success"></i>
                  {{ $t("Total valids") }}:
                  <a-counter :delay="2000" :value="totalValid" />
                </span>
                <span class="mx-4" />
                <span class="legend text-uppercase">
                  <i class="fa fa-circle text-danger"></i>
                  {{ $t("Total invalids") }}:
                  <a-counter :delay="2000" :value="totalInvalid" />
                </span>
              </td>
            </tr>
          </tfoot>
        </table>

        <!-- Upload zone -->
        <div v-if="!disabled">
          <h5 class="as-title">{{ $t("Select a file from your disk") }}</h5>
          <div class="row">
            <div class="col-sm-6 col-12 text-center p-3">
              <div class="dropzone p-3" id="tb-dropzone">
                <div
                  v-show="$refs.targetbase && $refs.targetbase.dropActive"
                  class="drop-active"
                >
                  <h4>{{ $t("Drop here to upload as TARGET-BASE") }}</h4>
                </div>
                <div>
                  <p>
                    {{ $t("Drop your TARGET-BASE files here to upload")
                    }}<br />{{ $t("or") }}
                  </p>
                  <file-upload
                    class="btn btn-success"
                    ref="targetbase"
                    v-model="uploads.targetBaseFiles"
                    name="targetbase"
                    :post-action="fileUploadUrl"
                    :headers="uploadHeaders"
                    :multiple="true"
                    :directory="false"
                    drop="#tb-dropzone"
                    :disabled="disabled"
                    @input-file="uploadStateChanged"
                    @input-filter="checkDroppedTB"
                    :data="uploadData"
                  >
                    <i class="nc-icon nc-simple-add"></i>
                    {{ $t("Select Targetbase") }}
                  </file-upload>
                </div>
              </div>
            </div>
            <div class="col-sm-6 col-12 text-center p-3">
              <div class="dropzone p-3" id="bl-dropzone">
                <div
                  v-show="$refs.blacklist && $refs.blacklist.dropActive"
                  class="drop-active"
                >
                  <h4>{{ $t("Drop here to upload as BLACKLIST") }}</h4>
                </div>
                <div>
                  <p>
                    {{ $t("Drop your BLACKLIST files here to upload") }}<br />{{
                      $t("or")
                    }}
                  </p>
                  <file-upload
                    class="btn btn-dark"
                    ref="blacklist"
                    v-model="uploads.blacklistFiles"
                    name="blacklist"
                    :post-action="fileUploadUrl"
                    :headers="uploadHeaders"
                    :multiple="true"
                    :directory="false"
                    drop="#bl-dropzone"
                    :disabled="disabled"
                    @input-file="uploadStateChanged"
                    @input-filter="checkDroppedBL"
                  >
                    <i class="nc-icon nc-simple-add"></i>
                    {{ $t("Select Blacklist") }}
                  </file-upload>
                </div>
              </div>
            </div>
          </div>

          <div class="row" v-if="hasPendingUploads">
            <div class="col-12">
              <!-- Start upload -->
              <button
                type="button"
                class="btn btn-info"
                v-if="!uploadInProgress"
                @click.prevent="startUpload"
              >
                <i class="nc-icon nc-minimal-up" aria-hidden="true"></i>
                {{ $t("Start Upload") }}
              </button>

              <!-- Stop upload -->
              <button
                type="button"
                class="btn btn-danger"
                v-else
                @click.prevent="stopUpload"
              >
                <i class="fa fa-stop" aria-hidden="true"></i>
                {{ $t("Stop Upload") }}
              </button>
            </div>
          </div>
        </div>

        <!-- Upload zone -->
        <div v-if="!disabled" class="mt-4">
          <h5 class="as-title">{{ $t("From account configuration") }}</h5>
          <div class="row mt-2">
              <template v-for="key in 1">
                <div class="col-6">
                  <div class="text-left">{{ $t("Select a file from your account") }}</div>
                  <el-select
                    :placeholder="$t(`Select other file for ${(key - 1) === 0 ? 'whitelist' : 'blacklist'}`)"
                    multiple
                    filterable
                    clearable
                    collapse-tags
                    :disabled="disabled"
                    v-model="accountFilesValue[key - 1]"
                    style="width: 100%;"
                  >
                    <el-option
                      v-for="file in accountFilesOptions[key - 1]"
                      :label="file.name"
                      :key="file.id"
                      :value="file.id"
                    >{{file.name}}</el-option>
                  </el-select>
                </div>
              </template>
              <div class="col-6">
                <div class="text-left">{{ $t("Use blacklist from account") }}</div>
                <div class="align-left">
                  <p-switch 
                    :disabled="disabled"
                    :value="castUseAccountBlacklistVar"
                    type="primary"
                    on-text="ON"
                    off-text="OFF"
                    @input="sendVariableValue"
                  ></p-switch>
                </div>
              </div>

              <button
                type="button"
                class="btn btn-info mx-auto"
                @click.prevent="loadAccountFiles"
                v-if="viewLoadAccountFiles"
              >
                {{ $t("Load files") }}
              </button>
          </div>
        </div>
      </div>
    </div>

    




    

    <!-- <div>
            <upload-observer @observed-value-stop="onFileObservedStop" 
                @observed-value="onFileObserved" 
                v-for="(file, key) in filesUploaded" :uploadId="file.id" :key="key">
            </upload-observer>
        </div> -->
  </div>
</template>

<script>
//import Uploader from "src/app-components/Uploader";
//import UploaderObserver from "src/app-components/UploadObserver"
import { flattenDeep } from "lodash";
import Dropdown from "src/custom-components/UIComponents/Dropdown";
import AnimatedCounter from "src/app-components/reports-components/AnimatedCounter";
import FileDownload from "src/app-components/buttons-components/FileDownload";
import FileRemove from "src/app-components/buttons-components/FileRemove";
import swal from "sweetalert2";
import api from "src/api";
import sio from "@/sio";
import PSwitch from "src/custom-components/UIComponents/Switch.vue";

const VueUploadComponent = require("vue-upload-component");

const TEXT = {
  SUCCESS: "success",
  VALIDATING_FILE: "validating...",
};

export default {
  inject: {
    getError: "getError",
    parentValidator: "parentValidator",
  },

  name: "target-uploader",

  components: {
    //Uploader,
    FileDownload,
    FileRemove,
    [AnimatedCounter.name]: AnimatedCounter,
    //[UploaderObserver.name]: UploaderObserver,
    "drop-down": Dropdown,
    "file-upload": VueUploadComponent,
    PSwitch
  },

  props: {
    value: Array,
    withFiles: {
      required: false,
      type: Array,
      default: () => [],
    },
    bookmark: {
      type: Object,
      required: false,
      default: () => {},
    },
    currentCreativities: {
      type: Array,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useAccountBlacklistVar:{
      type: Object,
      default: {
        value: true,
        variable_id: 120
      }
    }, 
    disableRemove: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      n: 0,
      campaignFiles: JSON.parse(JSON.stringify(this.withFiles)),
      uploads: {
        targetBaseFiles: [],
        blacklistFiles: [],
      },
      fileUploadUrl: api.getBaseUrl() + "/uploads",
      uploadHeaders: api.getHeaders(),
      filesValidating: {},
      allowedFileTypes: ["txt", "csv", "zip", "xls", "xlsx"],
      timer: null,
      useAccountBlacklistVar:{},
      accountFilesOptions: [
        [],
        []
      ],
      
      accountFilesValue: [
        [],
        []
      ],

      getAccount: false,
    };
  },

  created() {
    this.$validator = this.parentValidator;
    if(this.user) {
      this.getOtherFiles();
    }

    sio.onAny((event, obj) => {
      if(obj.service === "campaign-target-validator") {
        this.errorHeaders(obj.file_id);
      }
    });
  },

  mounted() {
    this.timer = setInterval(() => {
      let files = this.getFilesToValidate();
      if (files.length > 0) {
        const url = "/uploads/status/" + files.join("-");
        api.execute("get", url).then((res) => {
          if (res.status && res.data) {
            this.updateFiles(res.data);
          }
        });
      }
    }, 2000);
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  watch: {
    withFiles(newVal, oldVal) {
      this.campaignFiles.splice(
        0,
        this.campaignFiles.length,
        ...JSON.parse(JSON.stringify(newVal))
      );
    },

    user() {
      if(this.user && !this.getAccount) {
        this.getOtherFiles();
      }
    }
  },

  computed: {
    castUseAccountBlacklistVar(){
      return this.useAccountBlacklistVar.value == "0" ? false : true;
    },
    user() {
      return this.$store.state.user;
    },

    hasPendingUploads() {
      return (
        this.uploads.targetBaseFiles.filter(
          (file) => file.progress !== "100.00"
        ).length > 0 ||
        this.uploads.blacklistFiles.filter((file) => file.progress !== "100.00")
          .length > 0
      );
    },
    uploadInProgress() {
      return (
        (this.$refs.targetbase && this.$refs.targetbase.active) ||
        (this.$refs.blacklist && this.$refs.blacklist.active)
      );
    },

    shouldValidateEntries() {
      return false;
      //return ! ((this.files.length === 0 || this.withFiles.length === 0) && this.withEntries.total === 0) ? this.withEntries.success === 0 : false;
    },

    totalFiles() {
      return (
        this.campaignFiles.length +
        this.uploads.targetBaseFiles.length +
        this.uploads.blacklistFiles.length
      );
    },

    totalValid() {
      let result = 0;

      result += this.campaignFiles
        .map((file) => {
          // if (file.file_type != "TARGET_BASE") {
          if (file.file_type != "TARGET_BASE" && file.file_type != "ACCOUNT_OTHER") {
            return 0;
          }

          if (file.validationState) {
            return parseInt(file.validationState._count_success || 0);
          }

          return parseInt(file._count_success || 0);
        })
        .reduce((acc, val) => val + acc, 0);

      result += this.uploads.targetBaseFiles
        .filter((file) => !!file.accountFile)
        .map((file) => {
          const accountFile = file.accountFile;

          if (accountFile.validationState) {
            return parseInt(accountFile.validationState._count_success || 0);
          }

          return parseInt(accountFile._count_success || 0);
        })
        .reduce((acc, val) => val + acc, 0);

      return result;
    },

    totalInvalid() {
      let result = 0;

      result += this.campaignFiles
        .map((file) => {
          // if (file.file_type != "TARGET_BASE") {
          if (file.file_type != "TARGET_BASE" && file.file_type != "ACCOUNT_OTHER") {
            return 0;
          }

          if (file.validationState) {
            return parseInt(file.validationState._count_error || 0);
          }

          return parseInt(file._count_error || 0);
        })
        .reduce((acc, val) => val + acc, 0);

      result += this.uploads.targetBaseFiles
        .filter((file) => !!file.accountFile)
        .map((file) => {
          const accountFile = file.accountFile;

          if (accountFile.validationState) {
            return parseInt(accountFile.validationState._count_error || 0);
          }

          return parseInt(accountFile._count_error || 0);
        })
        .reduce((acc, val) => val + acc, 0);

      return result;
    },
    uploadData() {
      // data for validation task
      return {
        validation: JSON.stringify({
          placeholders: this.placeholdersInUse,
        }),
      };
    },

    creativitiesInUse() {
      try {
        return this.currentCreativities.map((c) => {
          return c.bookmark_perf_text_hash;
        });
      } catch (error) {
        return [];
      }
    },

    selectedCreatives(){
      if (
        typeof this.bookmark == "undefined" ||
        !("creatives" in this.bookmark)
      ) {
        return [];
      }

      return this.bookmark.creatives.filter(creative => this.creativitiesInUse.includes(creative.hash));
    },

    placeholdersInUse() {
      if (
        typeof this.bookmark == "undefined" ||
        !("placeholders" in this.bookmark) ||
        this.bookmark.placeholders.length === 0
      ) {
        return [];
      }

      const phInUseSet = new Set();
      const regex = /\{(\w+)\}/g;
      for(const creative of this.selectedCreatives){
        if(!creative.variables) continue;
        for(const variable of creative.variables){
          const matches = [ ...variable.value.matchAll(regex) ].map(match => match[1]);
          for(const match of matches){
            phInUseSet.add(match.toLowerCase());
          }
        }
      }

      const phInUse = [ ...phInUseSet.values() ];
      
      return this.bookmark.placeholders.
        filter(ph => phInUse.includes(ph.name.toLowerCase())).
        map(ph => {
          const transformed = JSON.parse(JSON.stringify(ph));
          if(transformed.alias && transformed.alias.length > 0){
            transformed.alias = transformed.alias.map(alias => alias.alias);
          }
          return transformed;
        });
    },

    viewLoadAccountFiles() {
      return this.accountFilesValue[0].length > 0 || this.accountFilesValue[1].length > 0;
    }
  },

  methods: {
    sendVariableValue(event) {
      this.useAccountBlacklistVar.value = Number(event)
      this.$emit("onUpdateUseAccountBlacklist", { value: this.useAccountBlacklistVar.value, prop: this.useAccountBlacklistVar });
      //this.$emit("onUpdateVariables", { value: $event, prop: "variables" });
    },

    errorHeaders(id = null) {
      let file_index = this.uploads.targetBaseFiles.findIndex(file => file.accountFile.id === id);
      if(file_index !== -1) {
        this.removeUpload(this.uploads.targetBaseFiles[file_index]);
      
        this.$notify({
          title: "File error",
          message: "An error occurred while processing the file",
          icon: "fa fa-info",
          horizontalAlign: "right",
          verticalAlign: "top",
          type: "danger",
        });
      }
    },

    async loadAccountFiles() {
      let otherFiles = this.accountFilesValue[0].map((id) => {
        let file = this.accountFilesOptions[0].find(opt => opt.id === id);
        file = {...file};
        if(file) {
          file.progress = "100.00";
          file.accountFile = {...file};
        }
        this.uploads.targetBaseFiles.push(file);
        return file;
      })
      
      let blacklistFiles = this.accountFilesValue[1].map((id) => {
        let file = this.accountFilesOptions[1].find(opt => opt.id === id);
        file = {...file};
        if(file) {
          file.progress = "100.00";
          file.accountFile = {...file};
        }
        this.uploads.blacklistFiles.push(file);
        return file;
      })

      this.updateFiles(otherFiles.concat(blacklistFiles));

      this.accountFilesValue[0] = [];
      this.accountFilesValue[1] = [];
    },

    getFileStatus(file) {
      if (file.processed) {
        return "success";
      }

      return "validating...";
    },

    getFileValidCount(file) {
      const raw = file.validationState
        ? file.validationState._count_success
        : file._count_success;
      const val = parseInt(raw || 0);

      return val.toLocaleString("en-US").replace(",", ".");
    },

    getFileInvalidCount(file) {
      const raw = file.validationState
        ? file.validationState._count_error
        : file._count_error;
      const val = parseInt(raw || 0);

      return val.toLocaleString("en-US").replace(",", ".");
    },

    getUploadStatus(file) {
      if (file.error) {
        return file.error;
      }

      if (file.active) {
        return "uploading";
      }

      if (file.accountFile) {
        if (file.accountFile.file_type == "BLACKLIST") {
          return TEXT.SUCCESS;
        }

        if (file.accountFile.processed) {
          return TEXT.SUCCESS;
        }

        return TEXT.VALIDATING_FILE;
      }

      return "upload pending";
    },

    /**
     * Gets an array with the ID's of the account_user_files to check the
     * validation progress
     */
    getFilesToValidate() {
      const campaignFiles = this.withFiles
        .filter((file) => !file.processed)
        .map((file) => file.id);

      const tbFiles = this.uploads.targetBaseFiles
        .filter((file) => file.accountFile && !file.accountFile.processed)
        .map((file) => file.accountFile.id);

      const blFiles = this.uploads.blacklistFiles
        .filter((file) => file.accountFile && !file.accountFile.processed)
        .map((file) => file.accountFile.id);

      return campaignFiles.concat(tbFiles, blFiles);
    },

    getProgressClass(file) {
      return {
        "progress-bar": true,
        "progress-bar-striped": true,
        "bg-danger": file.error,
        "progress-bar-animated": file.active,
      };
    },

    removeFile(file) {
      this.$emit("fileRemoved", file);
    },

    removeUpload(file) {
      if (file.file_type == "TARGET_BASE") {
        this.$refs.targetbase.remove(file);
      } else if (file.file_type == "BLACKLIST") {
        this.$refs.blacklist.remove(file);
      }else if (file.file_type == "ACCOUNT_OTHER") {
        let index = this.uploads.targetBaseFiles.findIndex(targetBase => targetBase.id === file.id);
        if(index !== -1) {
          this.uploads.targetBaseFiles.splice(index, 1);
        }
      }

      this.$emit("fileRemoved", file.accountFile);
    },

    uploadStateChanged: function(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        if (newFile.xhr && newFile.xhr.status === 200) {
          const accountFile = newFile.response.data;
          if (accountFile.file_type == "TARGET_BASE") {
            this.$refs.targetbase.update(newFile, { accountFile: accountFile });
          } else if (accountFile.file_type == "BLACKLIST") {
            this.$refs.blacklist.update(newFile, { accountFile: accountFile });
          }

          this.$emit("fileProcessed", accountFile);
        }
      }
    },

    checkDroppedTB: function(newFile, oldFile, prevent) {
      this.checkDroppedFile(newFile, oldFile, prevent, "TARGET_BASE");
    },

    checkDroppedBL: function(newFile, oldFile, prevent) {
      this.checkDroppedFile(newFile, oldFile, prevent, "BLACKLIST");
    },

    checkDroppedFile: function(newFile, oldFile, prevent, fileType) {
      if (!newFile) {
        return;
      }

      if (newFile && !oldFile) {
        // Filter files
        const ext = newFile.name
          .substr(newFile.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (!this.allowedFileTypes.includes(ext)) {
          swal.fire({
            icon: "error",
            title: "File type not supported",
            text: "Supported types: " + this.allowedFileTypes.join(", "),
          });
          return prevent();
        }

        newFile.file_type = fileType;
        newFile.postAction = newFile.postAction + "?file_type=" + fileType;
        //this.$emit("file-dropped", newFile);
      }
    },

    startUpload() {
      this.$refs.targetbase.active = true;
      this.$refs.blacklist.active = true;
    },

    stopUpload() {
      this.$refs.targetbase.active = false;
      this.$refs.blacklist.active = false;
    },

    updateFiles(newState) {
      for (let newFileState of newState) {
        let index = this.campaignFiles.findIndex(
          (file) => file.id === newFileState.id
        );
        if (index >= 0) {
          this.$set(this.campaignFiles, index, newFileState);
          if (newFileState.processed) {
            this.$emit("fileProcessed", newFileState);
          }
          continue;
        }

        //search in targetBaseFiles
        let uploadFile = this.uploads.targetBaseFiles.find(
          (upload) =>
            upload.accountFile && upload.accountFile.id === newFileState.id
        );
        if (uploadFile) {
          this.$refs.targetbase.update(uploadFile, {
            accountFile: newFileState,
          });
          if (newFileState.processed) {
            this.$emit("fileProcessed", newFileState);
          }
          continue;
        }

        uploadFile = this.uploads.blacklistFiles.find(
          (upload) =>
            upload.accountFile && upload.accountFile.id === newFileState.id
        );

        if (uploadFile) {
          this.$refs.blacklist.update(uploadFile, {
            accountFile: newFileState,
          });
          if (newFileState.processed) {
            this.$emit("fileProcessed", newFileState);
          }
          continue;
        }
      }
    },

    // Event dispatched when user remove a previous saved file
    withFileRemoved(index, file) {
      this.$delete(this.withFiles, index);
      this.$emit("fileRemoved", file);
    },

    onFileRemoved(file) {
      this.$emit("fileRemoved", file);
    },

    async getOtherFiles() {
      this.getAccount = true;
      // let data = (await api.getAccount(this.$store.getters.appUser.selected_account_id)).data
      let data = (await api.getAccount(this.$store.state.user.selected_account_id)).data
      
      if(data) {
        if(data.accountUserFiles) {
          data.accountUserFiles.forEach(file => {
            if(file.file_type === "ACCOUNT_OTHER") {
              this.accountFilesOptions[0].push(file);
            }else if(file.file_type === "ACCOUNT_BLACKLIST") {
              this.accountFilesOptions[1].push(file);
            }
          })
        }
      }
    }
  },
};
</script>

<style scoped>
.loading {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropzone {
  border: 2px dashed #51cbce;
  border-radius: 5px;
}

.dropzone > .align-middle {
  text-align: center;
  margin-top: 50px;
}

.dropzone .drop-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.dropzone .drop-active h4 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  /*font-size: 40px;*/
  color: #fff;
  padding: 0;
}
</style>
