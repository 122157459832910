export default {
  id: 'text-plugin',
  beforeDraw(chart) {
    const { ctx, chartArea, config } = chart;
    if (!config.options.elements?.center) return;

    const centerConfig = config.options.elements.center;
    const fontStyle = centerConfig.fontStyle || 'Arial';
    const txt = centerConfig.text || '';
    const color = centerConfig.color || '#000';
    const sidePadding = centerConfig.sidePadding || 20;

    const width = chart.width;
    const height = chart.height;
    const sidePaddingCalculated = (sidePadding / 100) * width;

    ctx.font = `30px ${fontStyle}`;
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = width - sidePaddingCalculated;
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(30 * widthRatio);
    const fontSizeToUse = Math.min(newFontSize, height);

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `${fontSizeToUse}px ${fontStyle}`;
    ctx.fillStyle = color;

    const centerX = (chartArea.left + chartArea.right) / 2;
    const centerY = (chartArea.top + chartArea.bottom) / 2;
    ctx.fillText(txt, centerX, centerY);
  }
};
