<template>
  <footer class="footer">
    <div class="container-fluid">
      <!--
      <nav class="pull-left">
        <ul>
          <li>
            <a href="http://www.creative-tim.com">
              Creative Tim
            </a>
          </li>
          <li>
            <a href="http://blog.creative-tim.com">
              Blog
            </a>
          </li>
          <li>
            <a href="http://www.creative-tim.com/license">
              Licenses
            </a>
          </li>
        </ul>
      </nav>
      -->
      <div class="copyright pull-right">
        Powered by
        <a href="https://dynamo.tech/" target="_blank">DYNAMO</a> &copy; {{ currentYear }}
      </div>
    </div>
  </footer>
</template>
<script>
  const moment = require("moment")

  export default {
      data() {
          return {
              currentYear:  moment().format("YYYY")
          }
      }
  }
</script>

<style>
</style>
