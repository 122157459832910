import { Doughnut } from 'vue-chartjs/legacy'
import { Chart, registerables } from 'chart.js'
import { hexToRGB } from './utils'
import pluginChartText from './plugins/plugin-chart-text'

const centerTextPlugin = {
  id: "centerText",
  beforeDraw(chart) {
    const { width } = chart;
    const { height } = chart;
    const ctx = chart.ctx;
    ctx.restore();

    // Texto que quieres mostrar
    const text = chart.options.plugins.centerText.text || "";
    const fontSize = chart.options.plugins.centerText.fontSize || 20;
    const fontColor = chart.options.plugins.centerText.color || "#000";

    ctx.font = `${fontSize}px Arial`;
    ctx.fillStyle = fontColor;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    // Posición en el centro del gráfico
    const centerX = width / 2;
    const centerY = height / 2;

    ctx.fillText(text, centerX, centerY);
    ctx.save();
  }
};

Chart.register(...registerables, centerTextPlugin)

export default {
  name: 'doughnut-chart',
  extends: Doughnut,
  props: {
    labels: {
      type: [Object, Array],
      description: 'Chart labels. This is overridden when `data` is provided',
      default: () => []
    },
    datasets: {
      type: [Object, Array],
      description: 'Chart datasets. This is overridden when `data` is provided',
      default: () => []
    },
    data: {
      type: [Object, Array],
      description: 'Chart.js chart data (overrides all default data)'
    },
    color: {
      type: String,
      description: 'Chart color. This is overridden when `data` is provided'
    },
    secondaryColor: {
      type: String,
      description: 'Chart color. This is overridden when `data` is provided'
    },
    extraOptions: {
      type: Object,
      description: 'Chart.js options',
      default: () => ({})
    },
    title: {
      type: String,
      description: 'Chart title'
    }
  },
  mounted() {
    let fallBackColor = '#f96332'
    let color = this.color || fallBackColor
    const ctx = document.getElementById(this.chartId).getContext('2d')
    console.log('ctx', ctx)

    // Crear gradiente de relleno
    const gradientFill = ctx.createLinearGradient(0, 170, 0, 50)
    gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)')
    gradientFill.addColorStop(1, hexToRGB(color, 0.4))

    let chartOptions = {
      cutout: '80%', // En Chart.js 3+ se usa 'cutout' en vez de 'cutoutPercentage'
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          enabled: false
        },
        centerText: {
          text: this.data.length ? `${parseInt(this.data[0])}%` : '',
          fontSize: 40,
        }
      },
      ...this.extraOptions
    }

    this.renderChart({
      labels: this.labels || [],
      datasets: [{
        backgroundColor: [this.color, this.secondaryColor],
        borderWidth: 0,
        data: this.data || [],
        fill: true // Esto asegura que el color de fondo se aplique
      }]
    }, chartOptions);

  }
}
